import { Directive, OnChanges, Input, SimpleChanges, ElementRef, ChangeDetectorRef, NgZone } from '@angular/core';

@Directive({
    selector: '[appHighlight]'
})
export class HighlightDirective implements OnChanges {

    @Input() public appHighlight: any;
    domElement: any;

    constructor(
        private el: ElementRef<any>,
        public cd: ChangeDetectorRef,
        private zone: NgZone
    ) {
    }

    ngOnChanges(changes: SimpleChanges) {
        // tslint:disable-next-line:max-line-length
        if (changes.appHighlight && changes.appHighlight.previousValue && changes.appHighlight.currentValue !== changes.appHighlight.previousValue) {
            if (changes.appHighlight.previousValue > changes.appHighlight.currentValue) {
                setTimeout(() => {
                    this.el.nativeElement.classList.add('highlight-down');
                }, 100);
            } else {
                setTimeout(() => {
                    this.el.nativeElement.classList.add('highlight-up');
                }, 100);
            }

            this.zone.runOutsideAngular(() => {
                setTimeout(() => {
                    this.el.nativeElement.classList.remove('highlight-up');
                    this.el.nativeElement.classList.remove('highlight-down');
                }, 1500);
            });
        }
    }
}
