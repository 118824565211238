<div *ngIf="selectedMarket">
  <div class="modal-header">
    <h4 class="modal-title">Confirm delete</h4>
    <button type="button" class="close" aria-label="Close" (click)="closeModal()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <p>Are you sure you want to delete <strong>{{selectedMarket?.symbol}}</strong>?</p>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn themeBtn" (click)="removeFromWatchlist()">Confirm</button>
    <button type="button" class="btn themeBtn" (click)="closeModal()">Close</button>
  </div>
</div>

<div *ngIf="!selectedMarket">
  <div class="modal-header">
    <h4 class="modal-title">Confirm</h4>
    <button type="button" class="close" aria-label="Close" (click)="closeModal()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <p>Are you sure you want to {{action}}?</p>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn themeBtn" (click)="confirm()">Confirm</button>
    <button type="button" class="btn themeBtn" (click)="closeModal()">Close</button>
  </div>
</div>