import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { AuthGuard } from './helpers/auth.guard';
import { AdminGuard } from './helpers/admin.guard';


const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('./shared/shared.module').then(m => m.SharedModule),
    data: { title: 'home' }
  },
  {
    path: 'dashboard',
    loadChildren: () => import('./user/user.module').then(m => m.UserModule),
    data: { title: 'dashboard' },
    canActivate: [AuthGuard]
  },
  {
    path: 'admin',
    loadChildren: () => import('./admin/admin.module').then(m => m.AdminModule),
    data: { title: 'dashboard' },
    canActivate: [AdminGuard]
  },
  {
    path: 'admin-login',
    loadChildren: () => import('./admin-login/admin-login.module').then(m => m.AdminLoginModule),
    data: { title: 'admin-login' }
  },
  {
    path: '**',
    redirectTo: '',
    pathMatch: 'full'
  }
];


@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }