export const AppUserCred = {
    username: 'appuser@gmail.com',
    password: 'AppUser@123$',
    // live
    client_id: 'oMvF8FZuMvxbaI1q1tZ4itDLpD9vEuZht2FG2SRp',
    client_secret: 'xqx99W8HUb4AxeAixjgPZhWVSC6SFCOwx1vO5DDVcjdsyD19nI2E8igetamczxz6elOiQJkQLmYkrEBOHMF0qQYYFrtRbcB5j614YlFAS1y9PeG0OqJcp0EuNK7UZbC7',
    // staging
    // client_id: 'yVaeJwMJZ4XV7DLi9LFlQS95TKfiP4kcsi62V3ew',
    // client_secret: 'iaffdxA4SZGRShfKFOc5BBbjfcY4ztZgSWEFXGFsvE7daTZxuyC4v6N3k7GHs2uWtX4xBRWxto7vdR3PS0AWlE53mvHcSrPIjHlHWiyhYULFIuifkBIc4uG2f8ZKbQzr',
    grant_type: 'password'
};
