import { Injectable } from '@angular/core';
import {
  HttpEvent,
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
  HttpErrorResponse,
  HttpResponse
} from '@angular/common/http';
import { NgxSpinnerService } from 'ngx-spinner';
import { Router, ActivatedRoute } from '@angular/router';

import { Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { tap } from 'rxjs/operators';
import { AuthService } from '../services/auth.service';
import { SharedService } from '../services/shared.service';

@Injectable({
  providedIn: 'root'
})

export class AuthInterceptor implements HttpInterceptor {
  private pubToken: any;
  private authToken: any;
  constructor(
    private SpinnerService: NgxSpinnerService,
    private router: Router,
    private authService: AuthService,
    private shareService: SharedService
  ) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    this.pubToken = this.authService.getToken();
    this.authToken = this.authService.getUser();
    // tslint:disable-next-line:max-line-length
    if (req.url.includes('/search/') || req.url.includes('/watchlist/') || req.url.includes('_quote/') || req.url.includes('_quotes/') || req.url.includes('/orders_list/') || req.url.includes('/symbol_chart/') || req.url.includes('_chart/') || req.url.includes('/accounts/users_list/') || req.url.includes('/notification_seen/') || req.url.includes('/dashboard/') || req.url.includes('/blocked_market')|| req.url.includes('/forex_symbols')) {
      this.SpinnerService.hide();
    } else {
      this.SpinnerService.show();
      setTimeout(() => {
        /** spinner ends after 5 seconds */
        this.SpinnerService.hide();
      }, 60000);
    }
    let req1: any = req;

    if (this.pubToken && this.pubToken.access_token && (req.url.includes('/accounts/log_in/') || req.url.includes('accounts/forgot_password/') || req.url.includes('/accounts/create_account/') || req.url.includes('/contact_us/') || req.url.includes('/policies/'))) {
      req1 = req.clone({
        headers: req.headers.set('Authorization', `Bearer ${this.pubToken.access_token}`),
      });
    } else if (!this.authToken && this.pubToken) {
      req1 = req.clone({
        headers: req.headers.set('Authorization', `Bearer ${this.pubToken.access_token}`),
      });
    } else if ((this.authToken && this.authToken.access_token)) {
      req1 = req.clone({
        headers: req.headers.set('Authorization', `Bearer ${this.authToken.access_token}`),
      });
    }

    return next.handle(req1).pipe(
      tap(
        (event: HttpEvent<any>) => {
          if (event instanceof HttpResponse) {
            if (event.body && event.body.status === 401) {
              this.router.navigate(['/']);
            } else if (event.body.status === 111) {
            }
          }
        },
        (err: HttpErrorResponse) => {
          if (err.status == 500)
            this.shareService.getErrorAlert('Error, Try again');
        }
      ),
      finalize(() => this.SpinnerService.hide())
    );
  }
}
