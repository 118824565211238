import { BrowserModule } from '@angular/platform-browser';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgxSpinnerModule } from 'ngx-spinner';
import { ToastrModule } from 'ngx-toastr';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
// import { AdminLoginComponent } from './admin-login/admin-login.component';
// import { SharedModule } from './shared/shared.module';
// import { UserModule } from './user/user.module';
// import { AdminModule } from './admin/admin.module';

import { AuthGuard } from './helpers/auth.guard';
import { AuthService } from './services/auth.service';
import { SharedService } from './services/shared.service';
import { AdminService } from './services/admin.service';

import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AuthInterceptor } from './helpers/auth-interceptor';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
// import { ChartsModule } from '@progress/kendo-angular-charts';
import 'hammerjs';
import { DirectivesModule } from './directives.module';
import { NgxCaptchaModule } from 'ngx-captcha';
import { TextModalComponent } from './shared/text-modal/text-modal.component';
import { ConfirmModelComponent } from './admin/confirm-model/confirm-model.component';

@NgModule({
  declarations: [
    AppComponent,
    // AdminLoginComponent,
    ConfirmModelComponent,
    TextModalComponent
  ],
  imports: [
    BrowserModule,
    ReactiveFormsModule,
    FormsModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    // SharedModule,
    // UserModule,
    // AdminModule,
    HttpClientModule,
    ToastrModule.forRoot({
      timeOut: 1500,
      preventDuplicates: true,
    }),
    NgxSpinnerModule,
    NgbModule,
    // ChartsModule,
    // SocketIoModule,
    DirectivesModule,
    NgxCaptchaModule
  ],
  exports: [ConfirmModelComponent],
  providers: [AuthGuard, AuthService, SharedService, AdminService, { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true }],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AppModule { }
