import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable, Observer } from 'rxjs';
import { map } from 'rxjs/operators';

import { environment } from '../../environments/environment';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root'
})
export class DataService {
  private wSocket: any;
  private wsUrl = environment.ws_url;
  public observer: Observer<any>;
  public wallet$: BehaviorSubject<any> = new BehaviorSubject<any>(0);
  public notifications$: BehaviorSubject<any> = new BehaviorSubject<any>(null);

  constructor(
    private http: HttpClient,
    private authService: AuthService
  ) { }

  public connect(url) {
    this.wSocket = new WebSocket(`${this.wsUrl}${url}`);
    this.wSocket.onopen = e => console.log('client cennected');
    this.wSocket.onclose = e => console.log('client disconnected ');
    return this.wSocket;
  }

  getWallet(): Observable<any> {
    return this.wallet$.asObservable();
  }

  updateWallet(data: any) {
    this.wallet$.next(data);
  }


  getNotification(): Observable<any> {
    return this.notifications$.asObservable();
  }

  updateNotification(data: any) {
    this.notifications$.next(data);
  }


  getSymbolDetails(symbol: string, symbolName: string) {
    return this.http.get<any>(`${environment.apiUrl}/${symbolName}/${symbolName}_quote/${symbol}/`)
      .pipe(map(data => {
        return data;
      }));
  }

  getStocksSymbols(market: any, page: number) {
    return this.http.get<any>(`${environment.apiUrl}/${market.market_type}/${market.symbol_type}_symbols/?page=${page}`)
      .pipe(map(data => {
        return data;
      }));
  }

  getSymbolChartData(item: any) {
    const marketType = item.marketType === 'stocks' ? 'symbol' : item.marketType;
    return this.http.get<any>(`${environment.apiUrl}/${item.marketType}/${marketType}_chart/${item.symbol}/?chart_type=${item.chartType}`)
      .pipe(map(data => {
        return data;
      }));
  }



  getWatchlist() {
    return this.http.get<any>(`${environment.apiUrl}/accounts/watchlist/`)
      .pipe(map(data => {
        return data;
      }));
  }

  addToWatchlist(symbol) {
    return this.http.post<any>(`${environment.apiUrl}/accounts/add_market/${symbol.text}/?market_type=${symbol.types}`, symbol.text)
      .pipe(map(data => {
        return data;
      }));
  }

  removeFromWatchlist(payload) {
    // tslint:disable-next-line:max-line-length
    return this.http.post<any>(`${environment.apiUrl}/accounts/remove_market/${payload.symbol}/?market_type=${payload.types}`, payload.symbol)
      .pipe(map(data => {
        return data;
      }));
  }

  searchAccountAndMarkets(searchData?) {
    return this.http.post<any>(`${environment.apiUrl}/accounts/search/`, searchData)
      .pipe(map(data => {
        return data;
      }));
  }


  withdrawFundRequest(payload) {
    return this.http.post<any>(`${environment.apiUrl}/accounts/request_withdraw/`, payload)
      .pipe(map(data => {
        return data;
      }));
  }


  buyOrder(itemData, payload: any, userId, userType) {
    const url = userType === 'admin' ? `exchange=${itemData.exchange}&user=${userId}` : `exchange=${itemData.exchange}`;
    const symbol = itemData.symbol ? itemData.symbol.replace('^', '').trim() : itemData.symbol;
    return this.http.post<any>(`${environment.apiUrl}/order/buy_trade/${symbol}/?${url}`, payload)
      .pipe(map(data => {
        return data;
      }));
  }


  sellOrder(orderId, payload) {
    return this.http.patch<any>(`${environment.apiUrl}/order/sell_trade/${orderId}/`, payload)
      .pipe(map(data => {
        return data;
      }));
  }

  getOrderList(pageNumber: number, status: string) {
    return this.http.get<any>(`${environment.apiUrl}/order/orders_list/${status}/?page=${pageNumber}`)
      .pipe(map(data => {
        return data;
      }));
  }


  getStocksQuotes(page: number) {
    return this.http.get<any>(`${environment.apiUrl}/stocks/stocks_quotes/?page=${page}`)
      .pipe(map(data => {
        return data;
      }));
  }

  getForexQuotes(page: number) {
    return this.http.get<any>(`${environment.apiUrl}/forex/forex_symbols/?page=${page}`)
      .pipe(map(data => {
        return data;
      }));
  }


  // get plans
  getPlans() {
    return this.http.get<any>(`${environment.apiUrl}/app/plans/`)
      .pipe(map(data => {
        return data;
      }));
  }


  // Min wallet balance per user for trade
  getMinWalletBalance() {
    return this.http.get<any>(`${environment.apiUrl}/app/min_trade_amount/`)
      .pipe(map(data => {
        return data;
      }));
  }

  // update open trade
  updateOpenTrade(orderId, reqPayload) {
    return this.http.patch<any>(`${environment.apiUrl}/order/update_trade/${orderId}/`, reqPayload)
      .pipe(map(data => {
        return data;
      }));
  }

  addPortFolioPlans(reqPayload) {
    return this.http.post<any>(`${environment.apiUrl}/app/add_plan/`, reqPayload)
      .pipe(map(data => {
        return data;
      }));
  }

  editPortFolioPlans(planId, reqPayload) {
    return this.http.patch<any>(`${environment.apiUrl}/app/plans/${planId}/`, reqPayload)
      .pipe(map(data => {
        return data;
      }));
  }

  readNotification(notificationId, userId) {
    return this.http.patch<any>(`${environment.apiUrl}/app/notification_seen/${notificationId}/${userId}/`, {})
      .pipe(map(data => {
        return data;
      }));
  }

  deleteTradeHistory(tradeId) {
    return this.http.patch<any>(`${environment.apiUrl}/accounts/delete_trade/${tradeId}/`, {})
      .pipe(map(data => {
        return data;
      }));
  }

  deleteTransactionHistory(tradeId) {
    return this.http.patch<any>(`${environment.apiUrl}/accounts/delete_transaction/${tradeId}/`, {})
      .pipe(map(data => {
        return data;
      }));
  }


  getBlockUnblockMarkets(userId) {
    return this.http.get<any>(`${environment.apiUrl}/app/blocked_market/${userId}/`)
      .pipe(map(data => {
        return data;
      }));
  }

  updateBlockUnblockMarkets(userId, payload) {
    return this.http.patch<any>(`${environment.apiUrl}/app/edit_blocked_markets/${userId}/`, payload)
      .pipe(map(data => {
        return data;
      }));
  }


}
