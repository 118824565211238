import { Component, OnInit, OnDestroy, HostListener } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { fromEvent, Subject } from 'rxjs';

import { AuthService } from './services/auth.service';
import { DataService } from './services/data.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {
  title = 'fxonepro';
  loading = true;
  user: any;
  socket: any;
  interval: any;

  constructor(
    private authService: AuthService,
    private dataService: DataService,
    private router: Router
  ) {
    this.user = this.authService.getUser();
    if (this.user) {
      this.initialConnection();
    }
    if (this.user) {
      this.authService.logout(this.user).subscribe();
    }
    fromEvent(window, 'beforeunload').subscribe(e => {
      this.authService.logout(this.user).subscribe();
    });
  }

  ngOnInit(): any {
    this.setAppUser();
    if (this.user) {
      this.interval = setInterval(() => {
        this.getNotification();
      }, 100);
    }
  }



  setAppUser(): any {
    this.loading = true;
    this.authService.setAccessToken().subscribe(res => {
      if (res.access_token) {
        localStorage.setItem('pub-token', JSON.stringify(res));
      }
    },
      error => {
        this.loading = false;
      });
  }

  initialConnection() {
    const url = `/notifications/`;
    this.socket = this.dataService.connect(url);
  }

  getNotification() {
    if (this.socket.readyState === 1) {
      this.socket.send(JSON.stringify({ id: this.user.id }));
      this.socket.onmessage = (event) => {
        const response = JSON.parse(event.data);
        this.dataService.updateNotification(response.notifications);
      };
    } else {
      this.socket.onclose = e => {
        this.initialConnection();
      };
    }
  }

  @HostListener('window:beforeunload')
  doBeforeUnload() {
    // Alert the user window is closing 
    if (this.user) {
      this.authService.logout(this.user).subscribe();
    }
  }

  ngOnDestroy() {
    localStorage.removeItem('pub-token');
    const remember = this.authService.getRememberMe();
    if (remember) {
      localStorage.removeItem('auth-token');
    }
  }

}
