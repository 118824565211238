import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { BehaviorSubject, Subject } from 'rxjs';

import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AdminService {

  searchingData = new Subject();
  filterData = new Subject();
  userListFilters = new Subject();
  contactMessageCount = new BehaviorSubject(0);

  constructor(
    private http: HttpClient
  ) { }




  getUserList(page?: number, filters?: any) {
    // tslint:disable-next-line:forin
    for (const key in filters) {
      if (filters.bg_color === 'all') {
        delete filters.bg_color;
      } else if (filters.from === 0) {
        delete filters.from;
      } else if (filters.to === 0) {
        delete filters.to;
      } else if (filters.search_query === '') {
        delete filters.search_query;
      }
    }
    return this.http.get<any>(`${environment.apiUrl}/accounts/users_list/?page=${page}`, { params: filters })
      .pipe(map(data => {
        return data;
      }));
  }

  depositFund(userId: string, payload) {
    return this.http.patch<any>(`${environment.apiUrl}/accounts/deposit_funds/${userId}/`, payload)
      .pipe(map(data => {
        return data;
      }));
  }

  deductFund(userId: string, payload) {
    return this.http.patch<any>(`${environment.apiUrl}/accounts/withdraw_funds/${userId}/`, payload)
      .pipe(map(data => {
        return data;
      }));
  }

  withdrawFund(userId: string, payload) {
    return this.http.patch<any>(`${environment.apiUrl}/accounts/withdraw_funds/${userId}/`, payload)
      .pipe(map(data => {
        return data;
      }));
  }

  getTransactionHistory(page: number, id: number) {
    return this.http.get<any>(`${environment.apiUrl}/accounts/transection_history/?page=${page}&pk=${id}`)
      .pipe(map(data => {
        return data;
      }));
  }

  getWithdrawList() {
    return this.http.get<any>(`${environment.apiUrl}/accounts/withdraw_list/`)
      .pipe(map(data => {
        return data;
      }));
  }

  changeWithdrawSatatus(userId, payload) {
    return this.http.patch<any>(`${environment.apiUrl}/accounts/withdraw_done/${userId}/`, payload)
      .pipe(map(data => {
        return data;
      }));
  }

  getDashboard() {
    return this.http.get<any>(`${environment.apiUrl}/accounts/dashboard/`)
      .pipe(map(data => {
        return data;
      }));
  }

  blockUser(userId: string) {
    return this.http.patch<any>(`${environment.apiUrl}/accounts/toggle_block/${userId}/`, {})
      .pipe(map(data => {
        return data;
      }));
  }

  deleteUser(userId: string) {
    return this.http.patch<any>(`${environment.apiUrl}/accounts/toggle_active/${userId}/`, {})
      .pipe(map(data => {
        return data;
      }));
  }


  getUserContactUsMesages() {
    return this.http.get<any>(`${environment.apiUrl}/app/get_contact_us/`)
      .pipe(map(data => {
        return data;
      }));
  }

  markAsReadContactMessage(messageId: String) {
    return this.http.patch<any>(`${environment.apiUrl}/app/contact_msg/${messageId}/`, {})
      .pipe(map(data => {
        return data;
      }));
  }

  sendNotification(payload: any) {
    return this.http.post<any>(`${environment.apiUrl}/app/send_notification/`, payload)
      .pipe(map(data => {
        return data;
      }));
  }

  getTradeCloseHistory(pageNumber: number, userId: string) {
    return this.http.get<any>(`${environment.apiUrl}/order/orders_list/close/?page=${pageNumber}&user=${userId}`)
      .pipe(map(data => {
        return data;
      }));
  }

  deleteContactMessage(messageId: number) {
    return this.http.patch<any>(`${environment.apiUrl}/app/delete_contact_us/${messageId}/`, {})
      .pipe(map(data => {
        return data;
      }));
  }

  updateUserProfile(userId, payload) {
    return this.http.patch(`${environment.apiUrl}/accounts/profile/${userId}/`, payload)
      .pipe(map(res => {
        return res;
      }));
  }


  blockUserLogin(userId: string) {
    return this.http.patch<any>(`${environment.apiUrl}/accounts/block_login/${userId}/`, {})
      .pipe(map(data => {
        return data;
      }));
  }

}
