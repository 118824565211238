export const MarketTimes = [
    { exchange: 'Bolsa de Madrid', day: [1, 2, 3, 4, 5], time: { startTime: '12:00', endTime: '20:30' }, break: '20:30-12:00' },
    { exchange: 'Borsa Italiana', day: [1, 2, 3, 4, 5], time: { startTime: '12:00', endTime: '20:25' }, break: '20:25-12:00' },
    { exchange: 'Chicago Board Options Exchange', day: [1, 2, 3, 4, 5, 6], time: { startTime: '18:30', endTime: '01:00' }, break: '01:00-18:30' },
    { exchange: 'Copenhagen', day: [1, 2, 3, 4, 5], time: { startTime: '12:00', endTime: '19:55' }, break: '19:55-12:00' },
    { exchange: 'Euronext Amsterdam', day: [1, 2, 3, 4, 5], time: { startTime: '13:00', endTime: '21:30' }, break: '21:30-13:00' },
    { exchange: 'Euronext Brussels', day: [1, 2, 3, 4, 5], time: { startTime: '13:00', endTime: '21:30' }, break: '21:30-13:00' },
    { exchange: 'Euronext Lisbon', day: [1, 2, 3, 4, 5], time: { startTime: '13:00', endTime: '21:30' }, break: '21:30-13:00' },
    { exchange: 'Euronext Paris', day: [1, 2, 3, 4, 5], time: { startTime: '12:00', endTime: '20:30' }, break: '20:30-12:00' },
    { exchange: 'Frankfurt', day: [1, 2, 3, 4, 5], time: { startTime: '12:00', endTime: '20:30' }, break: '20:30-12:00' },
    { exchange: 'Helsinki', day: [1, 2, 3, 4, 5], time: { startTime: '12:00', endTime: '20:25' }, break: '20:25-12:00' },
    { exchange: 'Hong Kong Stock Exchange', day: [1, 2, 3, 4, 5], time: { startTime: '06:30', endTime: '13:00' }, break: '09:00-10:00 13:00-06:30' },
    { exchange: 'LSE', day: [1, 2, 3, 4, 5], time: { startTime: '12:00', endTime: '20:30' }, break: '20:30-12:00' },
    { exchange: 'LSE – Russian GDRs', day: [1, 2, 3, 4, 5], time: { startTime: '12:00', endTime: '20:30' }, break: '20:30-12:00' },
    { exchange: 'NASDAQ', day: [1, 2, 3, 4, 5, 6], time: { startTime: '18:30', endTime: '01:00' }, break: '01:00-18:30' },
    { exchange: 'NYSE', day: [1, 2, 3, 4, 5, 6], time: { startTime: '19:30', endTime: '02:00' }, break: '02:00-19:30' },
    { exchange: 'Oslo', day: [1, 2, 3, 4, 5], time: { startTime: '12:00', endTime: '19:20' }, break: '19:20-12:00' },
    { exchange: 'SIX', day: [1, 2, 3, 4, 5], time: { startTime: '12:00', endTime: '20:20' }, break: '20:20-12:00' },
    { exchange: 'Stockholm', day: [1, 2, 3, 4, 5], time: { startTime: '12:00', endTime: '20:25' }, break: '20:25-12:00' },
    { exchange: 'Tadawul Saudi Arabia', day: [1, 2, 3, 4], time: { startTime: '12:00', endTime: '17:00' }, break: '17:00-12:00' },
    { exchange: 'Toronto Stock Exchange', day: [1, 2, 3, 4, 5, 6], time: { startTime: '18:30', endTime: '01:00' }, break: '01:00-18:30' },
    { exchange: 'US STOCKS', day: [1, 2, 3, 4, 5, 6], time: { startTime: '18:30', endTime: '01:00' }, break: '01:00-18:30' },

    // commodities
    { exchange: 'COMMODITY', name: 'Oil', day: [1, 2, 3, 4, 5, 6], time: { startTime: '03:00', endTime: '01:30' }, break: '02:00-03:00' },
    // tslint:disable-next-line:max-line-length
    { exchange: 'COMMODITY', name: 'Gold', day: [1, 2, 3, 4, 5, 6], time: { startTime: '03:00', endTime: '01:30' }, break: '02:00-03:00' },
    // tslint:disable-next-line:max-line-length
    { exchange: 'COMMODITY', name: 'Silver', day: [1, 2, 3, 4, 5, 6], time: { startTime: '03:00', endTime: '01:30' }, break: '02:00-03:00' },
    // tslint:disable-next-line:max-line-length
    { exchange: 'COMMODITY', name: 'Copper', day: [1, 2, 3, 4, 5, 6], time: { startTime: '03:00', endTime: '01:30' }, break: '02:00-03:00' },
    { exchange: 'COMMODITY', name: 'Natural Gas', day: [1, 2, 3, 4, 5, 6], time: { startTime: '03:00', endTime: '01:30' }, break: '01:55-03:00' },
    { exchange: 'COMMODITY', name: 'Platinum', day: [1, 2, 3, 4, 5, 6], time: { startTime: '12: 00', endTime: '01:30' }, break: '01:55-12:00' },
    // tslint:disable-next-line:max-line-length
    { exchange: 'COMMODITY', name: 'Palladium Future Contract', day: [1, 2, 3, 4, 5, 6], time: { startTime: '03:00', endTime: '01:30' }, break: '02:00-03:00' },
    { exchange: 'COMMODITY', name: 'Sugar Future Contract', day: [1, 2, 3, 4, 5], time: { startTime: '12:30', endTime: '22: 00' }, break: '22: 00-12: 30' },
    { exchange: 'COMMODITY', name: 'Cocoa Future Contract', day: [1, 2, 3, 4, 5], time: { startTime: '13:45', endTime: '22: 30' }, break: '22: 30-13: 45' },
    { exchange: 'COMMODITY', name: 'Wheat Future Contract', day: [1, 2, 3, 4, 5], time: { startTime: '05:00', endTime: '23: 20' }, break: '23: 20-05: 0017: 45-18:30' },
    // tslint:disable-next-line:max-line-length
    { exchange: 'COMMODITY', name: 'Cotton No.7', day: [1, 2, 3, 4, 5], time: { startTime: '06:00', endTime: '23:20' }, break: '23:20-06:00' },
    // tslint:disable-next-line:max-line-length
    { exchange: 'COMMODITY', name: 'Aluminum Future Contract', day: [1, 2, 3, 4, 5], time: { startTime: '14:00', endTime: '23:00' }, break: '23: 00-14:00' },
    { exchange: 'COMMODITY', name: 'Nickel Future Contract', day: [1, 2, 3, 4, 5], time: { startTime: '05:00', endTime: '23:00' }, break: '23:00-05:00' },
    // tslint:disable-next-line:max-line-length
    { exchange: 'COMMODITY', name: 'Crude Oil Future June 21', day: [1, 2, 3, 4, 5, 6], time: { startTime: '03:00', endTime: '01:30' }, break: '02:00-03:00' },
    // tslint:disable-next-line:max-line-length
    { exchange: 'COMMODITY', name: 'Crude Oil Future July 21', day: [1, 2, 3, 4, 5, 6], time: { startTime: '03:00', endTime: '01:30' }, break: '02:00-03:00' },

    // index
    { exchange: 'index', name: 'US Dollar Index', day: [1, 2, 3, 4, 5, 6], time: { startTime: '03:00', endTime: '01:30' }, break: '02:00-05:00' },
    { exchange: 'index', name: 'China A50 Index', day: [1, 2, 3, 4, 5, 6], time: { startTime: '06:00', endTime: '01:30' }, break: '01:45-06:0013:30-14:00' },
    { exchange: 'index', name: 'SPX500', day: [1, 2, 3, 4, 5, 6], time: { startTime: '03:00', endTime: '01:15' }, break: '01:15-01:3002:00-03:00' },
    { exchange: 'index', name: 'NASDAQ100', day: [1, 2, 3, 4, 5, 6], time: { startTime: '03:00', endTime: '01:15' }, break: '01:15-01:3002:00-03:00' },
    { exchange: 'index', name: 'DJ30', day: [1, 2, 3, 4, 5, 6], time: { startTime: '03:00', endTime: '01:15' }, break: '01:15-01:3002:00-03:00' },
    { exchange: 'index', name: 'UK100', day: [1, 2, 3, 4, 5, 6], time: { startTime: '11:00', endTime: '01:00' }, break: '01:00-11:00' },
    { exchange: 'index', name: 'FRA40', day: [1, 2, 3, 4, 5, 6], time: { startTime: '11:00', endTime: '01:00' }, break: '01:00-11:00' },
    { exchange: 'index', name: 'GER30', day: [1, 2, 3, 4, 5, 6], time: { startTime: '05:30', endTime: '01:00' }, break: '01:00-05:30' },
    { exchange: 'index', name: 'AUS200', day: [1, 2, 3, 4, 5, 6], time: { startTime: '04:50', endTime: '01:30' }, break: '11:30-12:1002:00-04:50' },
    { exchange: 'index', name: 'ESP35', day: [1, 2, 3, 4, 5], time: { startTime: '11:00', endTime: '23:00' }, break: '23:00-11:00' },
    { exchange: 'index', name: 'JPN225', day: [1, 2, 3, 4, 5, 6], time: { startTime: '04:00', endTime: '01:15' }, break: '01:15-04:00' },
    { exchange: 'index', name: 'HKG50', day: [1, 2, 3, 4, 5, 6], time: { startTime: '06:15', endTime: '00:00' }, break: '09:00-10:0013:30-14:1500:00-06:15' },
    { exchange: 'index', name: 'EUSTX50', day: [1, 2, 3, 4, 5, 6], time: { startTime: '11:00', endTime: '01:00' }, break: '01:00-11:00' },

    // "FOREX"
    { exchange: 'FOREX', symbol: 'EURUSD', day: [1, 2, 3, 4, 5, 6], time: { startTime: '02:05', endTime: '01:30' } },
    { exchange: 'FOREX', symbol: 'USDJPY', day: [1, 2, 3, 4, 5, 6], time: { startTime: '02:05', endTime: '01:30' } },
    { exchange: 'FOREX', symbol: 'GBPUSD', day: [1, 2, 3, 4, 5, 6], time: { startTime: '02:05', endTime: '01:30' } },
    { exchange: 'FOREX', symbol: 'USDCHF', day: [1, 2, 3, 4, 5, 6], time: { startTime: '02:05', endTime: '01:30' } },
    { exchange: 'FOREX', symbol: 'NZDUSD', day: [1, 2, 3, 4, 5, 6], time: { startTime: '02:05', endTime: '01:30' } },
    { exchange: 'FOREX', symbol: 'EURGBP', day: [1, 2, 3, 4, 5, 6], time: { startTime: '02:05', endTime: '01:30' } },
    { exchange: 'FOREX', symbol: 'EURJPY', day: [1, 2, 3, 4, 5, 6], time: { startTime: '02:05', endTime: '01:30' } },
    { exchange: 'FOREX', symbol: 'GBPJPY', day: [1, 2, 3, 4, 5, 6], time: { startTime: '02:05', endTime: '01:30' } },
    { exchange: 'FOREX', symbol: 'AUDJPY', day: [1, 2, 3, 4, 5, 6], time: { startTime: '02:05', endTime: '01:30' } },
    { exchange: 'FOREX', symbol: 'AUDUSD', day: [1, 2, 3, 4, 5, 6], time: { startTime: '02:05', endTime: '01:30' } },
    { exchange: 'FOREX', symbol: 'EURCHF', day: [1, 2, 3, 4, 5, 6], time: { startTime: '02:05', endTime: '01:30' } },
    { exchange: 'FOREX', symbol: 'EURAUD', day: [1, 2, 3, 4, 5, 6], time: { startTime: '02:05', endTime: '01:30' } },
    { exchange: 'FOREX', symbol: 'EURCAD', day: [1, 2, 3, 4, 5, 6], time: { startTime: '02:05', endTime: '01:30' } },
    { exchange: 'FOREX', symbol: 'CADJPY', day: [1, 2, 3, 4, 5, 6], time: { startTime: '02:05', endTime: '01:30' } },
    { exchange: 'FOREX', symbol: 'CHFJPY', day: [1, 2, 3, 4, 5, 6], time: { startTime: '02:05', endTime: '01:30' } },
    { exchange: 'FOREX', symbol: 'USDHKD', day: [1, 2, 3, 4, 5, 6], time: { startTime: '02:05', endTime: '01:30' } },
    { exchange: 'FOREX', symbol: 'USDZAR', day: [1, 2, 3, 4, 5, 6], time: { startTime: '02:05', endTime: '01:30' } },
    { exchange: 'FOREX', symbol: 'USDRUB', day: [1, 2, 3, 4, 5, 6], time: { startTime: '12:00', endTime: '00:00', break: '00:00-12:00' } },
    { exchange: 'FOREX', symbol: 'USDCNH', day: [1, 2, 3, 4, 5, 6], time: { startTime: '02:05', endTime: '01:30' } },
    { exchange: 'FOREX', symbol: 'AUDCHF', day: [1, 2, 3, 4, 5, 6], time: { startTime: '02:05', endTime: '01:30' } },
    { exchange: 'FOREX', symbol: 'AUDCAD', day: [1, 2, 3, 4, 5, 6], time: { startTime: '02:05', endTime: '01:30' } },
    { exchange: 'FOREX', symbol: 'AUDNZD', day: [1, 2, 3, 4, 5, 6], time: { startTime: '02:05', endTime: '01:30' } },
    { exchange: 'FOREX', symbol: 'EURNZD', day: [1, 2, 3, 4, 5, 6], time: { startTime: '02:05', endTime: '01:30' } },
    { exchange: 'FOREX', symbol: 'GBPAUD', day: [1, 2, 3, 4, 5, 6], time: { startTime: '02:05', endTime: '01:30' } },
    { exchange: 'FOREX', symbol: 'GBPCHF', day: [1, 2, 3, 4, 5, 6], time: { startTime: '02:05', endTime: '01:30' } },
    { exchange: 'FOREX', symbol: 'GBPNZD', day: [1, 2, 3, 4, 5, 6], time: { startTime: '02:05', endTime: '01:30' } },
    { exchange: 'FOREX', symbol: 'NZDCAD', day: [1, 2, 3, 4, 5, 6], time: { startTime: '02:05', endTime: '01:30' } },
    { exchange: 'FOREX', symbol: 'NZDCHF', day: [1, 2, 3, 4, 5, 6], time: { startTime: '02:05', endTime: '01:30' } },
    { exchange: 'FOREX', symbol: 'NZDJPY', day: [1, 2, 3, 4, 5, 6], time: { startTime: '02:05', endTime: '01:30' } },
    { exchange: 'FOREX', symbol: 'CADCHF', day: [1, 2, 3, 4, 5, 6], time: { startTime: '02:05', endTime: '01:30' } },
    { exchange: 'FOREX', symbol: 'USDNOK', day: [1, 2, 3, 4, 5, 6], time: { startTime: '02:05', endTime: '01:30' } },
    { exchange: 'FOREX', symbol: 'USDSEK', day: [1, 2, 3, 4, 5, 6], time: { startTime: '02:05', endTime: '01:30' } },
    { exchange: 'FOREX', symbol: 'NOKSEK', day: [1, 2, 3, 4, 5, 6], time: { startTime: '02:05', endTime: '01:30' } },
    { exchange: 'FOREX', symbol: 'EURNOK', day: [1, 2, 3, 4, 5, 6], time: { startTime: '02:05', endTime: '01:30' } },
    { exchange: 'FOREX', symbol: 'EURSEK', day: [1, 2, 3, 4, 5, 6], time: { startTime: '02:05', endTime: '01:30' } },
    { exchange: 'FOREX', symbol: 'USDTRY', day: [1, 2, 3, 4, 5, 6], time: { startTime: '02:05', endTime: '01:30' } },
    { exchange: 'FOREX', symbol: 'USDMXN', day: [1, 2, 3, 4, 5, 6], time: { startTime: '02:05', endTime: '01:30' } },
    { exchange: 'FOREX', symbol: 'USDSGD', day: [1, 2, 3, 4, 5, 6], time: { startTime: '02:05', endTime: '01:30' } },
    { exchange: 'FOREX', symbol: 'GBPCAD', day: [1, 2, 3, 4, 5, 6], time: { startTime: '02:05', endTime: '01:30' } },
    { exchange: 'FOREX', symbol: 'ZARJPY', day: [1, 2, 3, 4, 5, 6], time: { startTime: '02:05', endTime: '01:30' } },
    { exchange: 'FOREX', symbol: 'EURPLN', day: [1, 2, 3, 4, 5, 6], time: { startTime: '02:05', endTime: '01:30' } },
    { exchange: 'FOREX', symbol: 'USDHUF', day: [1, 2, 3, 4, 5, 6], time: { startTime: '02:05', endTime: '01:30' } },
    { exchange: 'FOREX', symbol: 'EURHUF', day: [1, 2, 3, 4, 5, 6], time: { startTime: '02:05', endTime: '01:30' } },
    { exchange: 'FOREX', symbol: 'GBPHUF', day: [1, 2, 3, 4, 5, 6], time: { startTime: '02:05', endTime: '01:30' } },
    { exchange: 'FOREX', symbol: 'CHFHUF', day: [1, 2, 3, 4, 5, 6], time: { startTime: '02:05', endTime: '01:30' } },
    { exchange: 'FOREX', symbol: 'USDPLN', day: [1, 2, 3, 4, 5, 6], time: { startTime: '02:05', endTime: '01:30' } }

];
