import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { DataService } from 'src/app/services/data.service';
import { SharedService } from 'src/app/services/shared.service';


@Component({
  selector: 'app-confirm-model',
  templateUrl: './confirm-model.component.html',
  styleUrls: ['./confirm-model.component.scss']
})
export class ConfirmModelComponent implements OnInit {
  @Input() selectedMarket: any;
  @Input() action: string;
  @Input() message: string;
  @Output() passData: EventEmitter<any> = new EventEmitter();

  constructor(
    private shareService: SharedService,
    private dataService: DataService,
    public activeModal: NgbActiveModal
  ) { }

  ngOnInit(): void {
  }

  removeFromWatchlist() {
    const marketType = this.shareService.getMarketType(this.selectedMarket);
    const paylod = {
      symbol: this.selectedMarket ? this.selectedMarket.symbol.replace('^', '').trim() : '',
      types: marketType === 'stocks' || marketType === 'index' ? 'stocks' : marketType
    };
    this.dataService.removeFromWatchlist(paylod)
      .subscribe(data => {
        if (data) {
          this.activeModal.dismiss();
          this.shareService.getSuccessAlert(data.message);
          this.passData.emit();
        }
      });
  }

  confirm() {
    this.passData.emit();
    this.activeModal.dismiss();
  }

  closeModal() {
    this.activeModal.dismiss();
  }

  ngOnDestroy() {
    this.action = '';
  }


}
